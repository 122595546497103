import { Exclude } from 'class-transformer'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

export type ElementType =
  'twitter-screen-name' |
  'optional-twitter-screen-name' |
  'serial' |
  'gender' |
  'email' |
  'phone' |
  'name' |
  'name-kana' |
  'address-japan' |
  'birthday' |
  'custom'

interface ElementValidationContext {
  elements: Element[]
}

export abstract class Element {

  abstract type: ElementType

  readonly id = uuidv4()

  readonly fixed: boolean = false

  readonly validation = {
    existence: {
      fixed: false,
      required: true,
    },
  }

  @Exclude()
  schema: yup.AnySchema = yup.object()

  @Exclude()
  error: yup.ValidationError | null = null

  async validate(elements: Element[]) {
    try {
      const context: ElementValidationContext = {
        elements,
      }

      await this.schema.validate(this, {
        abortEarly: false,
        context,
      })

      this.error = null
      return true

    } catch (error) {
      this.error = error
      return false
    }
  }
}

abstract class RequiredElement extends Element {

  readonly fixed = true

  readonly validation = {
    existence: {
      fixed: true,
      required: true,
    },
  }
}

export class TwitterScreenNameElement extends RequiredElement {

  readonly type = 'twitter-screen-name'

  label = 'Twitterスクリーンネーム'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class OptionalTwitterScreenNameElement extends Element {

  readonly type = 'optional-twitter-screen-name'

  label = 'Twitterスクリーンネーム'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class SerialElement extends RequiredElement {

  readonly type = 'serial'

  label = 'ID'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class GenderElement extends Element {

  readonly type = 'gender'

  label = '性別'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class EmailElement extends Element {

  readonly type = 'email'

  label = 'メールアドレス'
  continueLabel = '確認用メールアドレス'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class PhoneElement extends Element {

  readonly type = 'phone'

  label = '電話番号'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class NameElement extends Element {

  readonly type = 'name'

  label = '氏名'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class KanaNameElement extends Element {

  readonly type = 'name-kana'

  label = '氏名（フリガナ）'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class JapaneseAddressElement extends Element {

  readonly type = 'address-japan'

  label = '住所'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class BirthdayElement extends Element {

  readonly type = 'birthday'

  label = '生年月日'

  readonly schema = yup.object({
    label: yup
      .string()
      .min(1)
      .max(100),
  })
}

export class CustomElement extends Element {

  readonly type = 'custom'

  question = ''

  readonly schema = yup.object({
    question: yup
      .string()
      .max(1000)
      .test({
        message: '自由項目名が重複しています。',
        test(question, context) {
          const { elements } = context.options.context as ElementValidationContext

          for (const element of elements) {
            if (element === context.parent) {
              break
            }

            if (!(element instanceof CustomElement)) {
              continue
            }

            if (element.question === question) {
              return false
            }
          }

          return true
        },
      }),
  })
}
